import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "@/shadcn/components/ui/card";
import { Button } from "@/shadcn/components/ui/button";
import { db } from '../../firebase/config';
import { doc, onSnapshot, collection, query, orderBy, limit, setDoc } from 'firebase/firestore';
import { Plus } from "lucide-react";
import { Alert, AlertDescription } from "@/shadcn/components/ui/alert";
import { Skeleton } from "@/shadcn/components/ui/skeleton";
import { toast } from "react-hot-toast";
import TransactionHistory from './TransactionHistory';
import AppLimitModal from './AppLimitModal';
import PaymentTour from './PaymentTour';
import { createSubscription } from './stripeService';

const APP_PRICE = 10;
const MIN_APPS = 1;
const MAX_APPS = 100;
const TOUR_KEY = 'payment_tour_completed';

const AppLimitManager = ({ userId }) => {
  const [appCount, setAppCount] = useState('1');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [lastTransaction, setLastTransaction] = useState(null);
  const [showTour, setShowTour] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/pricing') {
      const tourCompleted = localStorage.getItem(TOUR_KEY);
      if (!tourCompleted) {
        setShowTour(true);
      }
    } else {
      setShowTour(false);
    }

    if (!userId) {
      setIsDataLoading(false);
      return;
    }

    const userRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setCurrentUserData({ id: doc.id, ...doc.data() });
        const pendingSessionId = localStorage.getItem('stripe_session_id');
        if (pendingSessionId) {
          checkPendingSession(pendingSessionId, doc.data());
        }
      } else {
        setError('Usuário não encontrado');
      }
      setIsDataLoading(false);
    }, (error) => {
      console.error('Erro ao observar mudanças:', error);
      setError('Erro ao carregar dados do usuário');
      setIsDataLoading(false);
    });

    const transactionsRef = collection(db, 'users', userId, 'transactions');
    const transactionsQuery = query(transactionsRef, orderBy('createdAt', 'desc'), limit(1));
    
    const unsubscribeTransactions = onSnapshot(transactionsQuery, (snapshot) => {
      if (!snapshot.empty) {
        setLastTransaction(snapshot.docs[0].data());
      }
    });

    return () => {
      unsubscribe();
      unsubscribeTransactions();
    };
  }, [userId, location.pathname]);

  const handleTourComplete = async (neverShowAgain) => {
    setShowTour(false);
    if (neverShowAgain) {
      localStorage.setItem(TOUR_KEY, 'true');
      if (userId) {
        try {
          const tourPrefRef = doc(db, 'users', userId, 'preferences', 'tour');
          await setDoc(tourPrefRef, {
            paymentTourCompleted: true
          }, { merge: true });
        } catch (err) {
          console.error('Erro ao salvar preferências do tour:', err);
        }
      }
    }
  };

  const checkPendingSession = async (sessionId, userData) => {
    try {
      if (userData.subscriptionStatus === 'active' && userData.stripeSubscriptionId) {
        localStorage.removeItem('stripe_session_id');
      }
    } catch (error) {
      console.error('Erro ao verificar sessão:', error);
    }
  };

  const handleAddAppLimit = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const numApps = parseInt(appCount);
      if (isNaN(numApps) || numApps < MIN_APPS || numApps > MAX_APPS) {
        throw new Error(`Por favor, insira um número entre ${MIN_APPS} e ${MAX_APPS}`);
      }
  
      const hasActiveSubscription = currentUserData?.subscriptionStatus === 'active';
      const isUpgrade = hasActiveSubscription && numApps > (currentUserData?.appLimit || 0);
      const isDowngrade = hasActiveSubscription && numApps < (currentUserData?.appLimit || 0);
  
      if (isDowngrade && !window.confirm(
        'Reduzir seu limite de Aplicações disponiveis para hospedagem pode resultar na perda de acesso aos aplicativos existentes. Deseja continuar?'
      )) {
        setLoading(false);
        return;
      }

      const monthlyAmount = numApps * APP_PRICE;

      const response = await createSubscription(
        userId,
        numApps,
        currentUserData?.stripeCustomerId,
        hasActiveSubscription,
        monthlyAmount
      );

      if (response.success) {
        setIsModalOpen(false);
        toast.success(
          isUpgrade 
            ? 'Limite de aplicativos aumentado com sucesso'
            : isDowngrade
              ? 'Limite de aplicativos reduzido com sucesso'
              : 'Assinatura atualizada com sucesso'
        );
        return;
      }
      
      if (!response?.url) {
        throw new Error('Falha ao processar solicitação de assinatura');
      }

      localStorage.setItem('stripe_session_id', response.sessionId);
      localStorage.setItem('requested_app_limit', numApps.toString());
      localStorage.setItem('subscription_amount', monthlyAmount.toString());
      
      window.location.href = response.url;
  
    } catch (err) {
      console.error('Erro no processamento:', err);
      toast.error(err.message || 'Falha ao processar solicitação');
      setError(err.message || 'Falha ao processar solicitação. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setError(null);
    setAppCount(currentUserData?.appLimit?.toString() || '1');
  };

  if (!userId) {
    return (
      <Alert variant="destructive">
        <AlertDescription>
          Usuário não autenticado. Por favor, faça login para continuar.
        </AlertDescription>
      </Alert>
    );
  }

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4 grid gap-4">
        <Skeleton className="h-48 w-full" />
        <Skeleton className="h-48 w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="max-w-4xl mx-auto my-8">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle id="card-title" className="text-xl font-semibold">
            Limite de Aplicações disponíveis para hospedagem
          </CardTitle>
          <Button 
            id="modify-button"
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 hover:bg-blue-600 space-x-2"
            disabled={loading || isDataLoading}
          >
            <Plus className="h-4 w-4" />
            <span>
              {currentUserData?.subscriptionStatus === 'active' 
                ? 'Modificar o limite de hospedagem' 
                : 'Adicionar portas das Aplicações'}
            </span>
          </Button>
        </CardHeader>
        <CardContent className="space-y-4">
          {isDataLoading ? (
            <div className="space-y-2">
              <Skeleton className="h-8 w-24" />
              <Skeleton className="h-4 w-32" />
            </div>
          ) : (
            <div >
              <div id="app-limit" className="text-2xl font-bold">
                {currentUserData?.appLimit || 0}
              </div>         
              <div className="text-sm text-muted-foreground">
                {currentUserData?.appLimit === 1 ? 'Servidor de Aplicativo Disponível' : 'Servidores de Aplicativos Disponíveis'}
              </div>
              {currentUserData?.subscriptionStatus && (
                <div id="subscription-status" className="mt-2 space-y-1">
                  <div className="text-sm">
                    Status da Assinatura: {' '}
                    <span className={`font-medium ${
                      currentUserData.subscriptionStatus === 'active' 
                        ? 'text-green-600' 
                        : 'text-yellow-600'
                    }`}>
                      {currentUserData.subscriptionStatus === 'active' ? 'Ativa' : 'Inativa'}
                    </span>
                  </div>
                  {lastTransaction && (
                    <div className="text-sm text-muted-foreground">
                      Última atualização: {new Date(lastTransaction.createdAt.toDate()).toLocaleDateString()}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>

      <AppLimitModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleAddAppLimit}
        appCount={appCount}
        setAppCount={setAppCount}
        loading={loading}
        error={error}
        price={APP_PRICE}
        isUpdate={currentUserData?.subscriptionStatus === 'active'}
        currentAppLimit={currentUserData?.appLimit || 0}
      />

      <div id="transaction-history">
        <TransactionHistory userId={userId} />
      </div>

      {showTour && location.pathname === '/pricing' && 
        <PaymentTour onComplete={handleTourComplete} />
      }
    </div>
  );
};

export default AppLimitManager;