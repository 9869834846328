import React, { useState, useEffect } from 'react';
import { Button } from "@/shadcn/components/ui/button";
import { Checkbox } from "@/shadcn/components/ui/checkbox";
import { useToast } from "@/shadcn/hooks/use-toast";
import { Card, CardHeader, CardContent, CardFooter } from "@/shadcn/components/ui/card";
import { X } from 'lucide-react';

const BASE_WIDTH = 1920; 
const TOUR_STEPS = [
  {
    target: 'card-title',
    title: 'Bem-vindo ao Gerenciamento de Aplicações! 👋',
    description: 'Nesse ambiente você pode controlar o limite de aplicações disponíveis para hospedagem e fazer seus pagamentos.',
    getPosition: () => ({
      top: '8px',
      left: '320px',
      width: '200px',
      height: '60px'
    })
  },
  {
    target: 'app-limit',
    title: 'Limite Atual ⚡',
    description: 'Este é seu limite atual de aplicações para serem hospedadas disponíveis.',
    getPosition: () => ({
      top: '76px',
      left: '334px',
      width: '560px',
      height: '200px'
    })
  },
  {
    target: 'subscription-status',
    title: 'Status da Assinatura 📊',
    description: 'Acompanhe o status atual da sua assinatura e a data da última atualização.',
    getPosition: () => ({
      top: '202px',
      left: '355px',
      width: '200px',
      height: '30px'
    })
  },
  {
    target: 'modify-button',
    title: 'Modificar Limite 🔄',
    description: 'Clique aqui para aumentar ou diminuir seu limite de aplicações. O valor é $10,00 por aplicação.',
    getPosition: () => {
      const windowWidth = window.innerWidth;
      const ratio = windowWidth / BASE_WIDTH;

      return {
        top: '101px',
        left: `${Math.round(1045 * ratio)}px`,
        width: `${Math.round(280 * ratio)}px`,
        height: '40px'
      };
    }
  },
  {
    target: 'transaction-history',
    title: 'Histórico de Transações 📝',
    description: 'Acompanhe todas as alterações feitas em sua assinatura e pagamentos.',
    getPosition: () => ({
      top: '300px',
      left: '338px',
      width: '1050px',
      height: '100px'
    })
  }
];

const PaymentTour = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [neverShowAgain, setNeverShowAgain] = useState(false);
  const [highlightPosition, setHighlightPosition] = useState({});
  const { toast } = useToast();

  useEffect(() => {
    const updatePosition = () => {
      const newPosition = TOUR_STEPS[currentStep]?.getPosition();
      if (newPosition) {
        setHighlightPosition(newPosition);
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [currentStep]);

  const getCardPosition = () => {
    const padding = 20;
    const elementTop = parseInt(highlightPosition.top);
    const elementLeft = parseInt(highlightPosition.left);
    const elementWidth = parseInt(highlightPosition.width);
    const elementHeight = parseInt(highlightPosition.height);
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    // Determina posição vertical
    let top = elementTop + elementHeight + padding;
    if (top + 250 > windowHeight) {
      top = Math.max(padding, elementTop - 250 - padding);
    }

    // Determina posição horizontal
    let left = elementLeft + elementWidth + padding;
    if (left + 400 > windowWidth) {
      left = Math.max(padding, windowWidth - 400 - padding);
    }

    return {
      position: 'fixed',
      top: `${top}px`,
      left: `${left}px`,
      width: '400px'
    };
  };

  const handleComplete = () => {
    if (onComplete) {
      onComplete(neverShowAgain);
    }
    toast({
      title: "Tour concluído! 🎉",
      description: neverShowAgain 
        ? "Você optou por não ver mais o tour." 
        : "Você pode reiniciar o tour a qualquer momento.",
      duration: 3000,
    });
  };

  const currentStepData = TOUR_STEPS[currentStep];
  const isLastStep = currentStep === TOUR_STEPS.length - 1;

  return (
    <>
      <div className="fixed inset-0 bg-black/80 z-[60]" />

      <div
        className="fixed z-[70] bg-white/5 border border-white/10 rounded-lg transition-all duration-300"
        style={highlightPosition}
      />

      <Card 
        className="fixed z-[80] bg-zinc-900/95 border-zinc-800 transition-all duration-300"
        style={getCardPosition()}
      >
        <CardHeader className="p-3 border-b border-zinc-800">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-white flex items-center gap-2">
              {currentStepData.title}
            </h3>
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 rounded-md hover:bg-zinc-800"
              onClick={handleComplete}
            >
              <X className="h-4 w-4 text-zinc-400" />
            </Button>
          </div>
        </CardHeader>

        <CardContent className="p-3">
          <p className="text-sm text-zinc-400">
            {currentStepData.description}
          </p>
        </CardContent>

        <CardFooter className="flex flex-col gap-3 p-0">
          <div className="flex items-center justify-between w-full p-3 border-t border-zinc-800">
            <div className="flex gap-2">
              {currentStep > 0 && (
                <button
                  onClick={() => setCurrentStep(prev => prev - 1)}
                  className="px-3 py-1.5 text-sm text-white bg-transparent border border-zinc-800 rounded-md hover:bg-zinc-800"
                >
                  Anterior
                </button>
              )}
              <button
                onClick={() => {
                  if (currentStep < TOUR_STEPS.length - 1) {
                    setCurrentStep(prev => prev + 1);
                  } else {
                    handleComplete();
                  }
                }}
                className="px-3 py-1.5 text-sm text-black bg-white rounded-md hover:bg-white/90"
              >
                {isLastStep ? 'Concluir' : 'Próximo'}
              </button>
            </div>
            <span className="text-xs text-zinc-500">
              {currentStep + 1}/{TOUR_STEPS.length}
            </span>
          </div>

          {isLastStep && (
            <div className="px-3 pb-2 w-full">
              <label className="flex items-center gap-2 text-sm text-zinc-400">
                <Checkbox
                  checked={neverShowAgain}
                  onCheckedChange={setNeverShowAgain}
                  className="border-zinc-700 data-[state=checked]:bg-white data-[state=checked]:text-black"
                />
                Não mostrar mais o tour
              </label>
            </div>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

export default PaymentTour;