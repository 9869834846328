import React, { useState, useEffect } from 'react';
import { useAuthContext } from '@/hooks/useAuthContext';
import { useApplications } from '@/hooks/useApplications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCogs, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@/shadcn/components/ui/card';
import { Button } from '@/shadcn/components/ui/button';
import { Alert, AlertDescription } from '@/shadcn/components/ui/alert';
import { Skeleton } from '@/shadcn/components/ui/skeleton';
import { toast } from "react-hot-toast";
import { 
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/shadcn/components/ui/dialog';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, onSnapshot, getDoc, setDoc, collection } from 'firebase/firestore';
import { db } from '@/firebase/config';
import ApplicationTour from './ApplicationTour';

const ApplicationManager = () => {
  const { user } = useAuthContext();
  const { fetchUserApplications } = useApplications();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancelingApp, setCancelingApp] = useState(null);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showTour, setShowTour] = useState(false);

  const initializeUserPreferences = async (userId) => {
    try {
      const preferencesRef = doc(collection(db, 'users', userId, 'preferences'), 'tour');
      await setDoc(preferencesRef, {
        applicationTourCompleted: false,
        neverShowTour: false,
        createdAt: new Date(),
        updatedAt: new Date()
      }, { merge: true });

      return true;
    } catch (error) {
      console.error('Erro ao inicializar preferências:', error);
      return false;
    }
  };

  const loadApplications = async () => {
    if (!user?.uid) return;
    
    try {
      const apps = await fetchUserApplications(user.uid);
      const filteredApps = Array.isArray(apps) ? apps.filter(app => app && app.type) : [];
      setApplications(filteredApps);
      await loadUserData();
      setError(null);
      return filteredApps;
    } catch (err) {
      setError("Erro ao buscar aplicações: " + err.message);
      toast.error("Erro ao buscar aplicações");
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkTourStatus = async () => {
      if (!user?.uid) return;
      
      try {
        const apps = await loadApplications();
        const hasActiveApps = apps.length > 0;
        
        if (!hasActiveApps) {
          setShowTour(false);
          return;
        }

        const preferencesRef = doc(collection(db, 'users', user.uid, 'preferences'), 'tour');
        const tourPrefDoc = await getDoc(preferencesRef);
        
        if (!tourPrefDoc.exists()) {
          const initialized = await initializeUserPreferences(user.uid);
          if (initialized) {
            setShowTour(true);
          }
        } else if (!tourPrefDoc.data()?.applicationTourCompleted && !tourPrefDoc.data()?.neverShowTour) {
          setShowTour(true);
        }
      } catch (err) {
        console.error('Erro ao verificar status do tour:', err);
        toast.error('Erro ao verificar preferências do tour');
        setShowTour(false);
      }
    };

    checkTourStatus();
  }, [user]);

  const handleTourComplete = async (neverShowAgain) => {
    if (!user?.uid) return;
    
    try {
      const preferencesRef = doc(collection(db, 'users', user.uid, 'preferences'), 'tour');
      
      await setDoc(preferencesRef, {
        applicationTourCompleted: true,
        neverShowTour: neverShowAgain,
        updatedAt: new Date()
      }, { merge: true });
      
      setShowTour(false);
      
    } catch (err) {
      console.error('Erro ao salvar preferências do tour:', err);
      toast.error('Erro ao salvar preferências do tour');
    }
  };

  const loadUserData = async () => {
    if (!user?.uid) return;
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        setUserData(userDocSnap.data());
      }
    } catch (err) {
      console.error('Erro ao carregar dados do usuário:', err);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const userDocRef = user ? doc(db, 'users', user.uid) : null;
    const unsubscribe = userDocRef && onSnapshot(userDocRef, 
      (docSnapshot) => {
        if (docSnapshot.exists() && isMounted) {
          setUserData(docSnapshot.data());
        }
      },
      (error) => {
        console.error("Erro ao observar mudanças:", error);
        toast.error("Erro ao monitorar atualizações");
      }
    );

    return () => {
      isMounted = false;
      if (unsubscribe) unsubscribe();
    };
  }, [user]);

  const handleCancelSubscription = async (appId) => {
    if (!user?.uid || !appId) return;
    
    setCancelingApp(appId);

    try {
      const functions = getFunctions();
      const cancelApplication = httpsCallable(functions, 'cancelApplication');
      
      const result = await cancelApplication({
        userId: user.uid,
        applicationId: appId
      });

      if (result.data.success) {
        await loadApplications();
        toast.success("Aplicação cancelada com sucesso");
        setShowCancelDialog(false);
      } else {
        throw new Error(result.data.message || 'Falha ao cancelar aplicação');
      }
    } catch (err) {
      console.error('Erro ao cancelar aplicação:', err);
      toast.error("Erro ao cancelar aplicação: " + (err.message || 'Tente novamente mais tarde'));
    } finally {
      setCancelingApp(null);
      setSelectedApp(null);
    }
  };

  const handleCancelClick = (app) => {
    setSelectedApp(app);
    setShowCancelDialog(true);
  };

  const getCurrentPrice = () => {
    return userData?.appLimit ? userData.appLimit * 10 : applications.length * 10;
  };

  const getNewPrice = () => {
    const currentLimit = userData?.appLimit || applications.length;
    return Math.max(1, currentLimit - 1) * 10;
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4 grid gap-4">
        <Skeleton className="h-48 w-full" />
        <Skeleton className="h-48 w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="max-w-4xl mx-auto my-8">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <div className="max-w-4xl mx-auto py-8 px-4 space-y-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="app-header text-2xl font-bold">
            Suas portas para as Aplicações
          </h2>
          {userData && (
            <div className="app-limit text-sm text-gray-600">
              Limite atual: {userData.appLimit} {userData.appLimit === 1 ? 'aplicação' : 'aplicações'}
            </div>
          )}
        </div>
        
        {applications.length === 0 ? (
          <Card className="p-6 text-center">
            <CardDescription>
              Você ainda não possui aplicações ativas.
            </CardDescription>
          </Card>
        ) : (
          <>
            {applications.map((app) => (
              <Card key={app.id} className="app-card shadow-lg">
                <CardHeader>
                  <div className="flex justify-between items-center">
                    <div>
                      <CardTitle className="text-xl">{app.type}</CardTitle>
                      <CardDescription>{app.url_acesso}</CardDescription>
                    </div>
                    <FontAwesomeIcon 
                      icon={app.type?.toLowerCase() === 'n8n' ? faBolt : faCogs} 
                      className="text-gray-400 text-2xl"
                    />
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="app-status text-sm text-gray-500">
                    Status: <span className="text-green-500">Ativo</span>
                  </div>
                  <div className="text-sm text-gray-500">
                    Criado em: {app.createdAt ? new Date(app.createdAt.seconds * 1000).toLocaleDateString() : 'Data indisponível'}
                  </div>
                </CardContent>
                <CardFooter className="app-actions justify-end space-x-4">
                  <a
                    href={app.url_acesso}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Acessar
                  </a>
                  
                  <Button 
                    variant="destructive" 
                    size="sm"
                    onClick={() => handleCancelClick(app)}
                    disabled={cancelingApp === app.id}
                  >
                    {cancelingApp === app.id ? 'Cancelando...' : 'Cancelar porta'}
                  </Button>
                </CardFooter>
              </Card>
            ))}

            <Dialog 
              open={showCancelDialog} 
              onOpenChange={setShowCancelDialog}
              className="cancel-dialog"
            >
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Confirmar Cancelamento</DialogTitle>
                  <DialogDescription className="space-y-2">
                    <p>
                      Tem certeza que deseja cancelar a porta desta aplicação?
                    </p>
                    <div className="mt-2 p-3 bg-gray-50 rounded-lg border">
                      <div className="text-sm space-y-1">
                        <div className="flex justify-between">
                          <span>Valor atual:</span>
                          <span>${getCurrentPrice()}.00/mês</span>
                        </div>
                        <div className="flex justify-between font-medium">
                          <span>Novo valor:</span>
                          <span>${getNewPrice()}.00/mês</span>
                        </div>
                        <div className="flex justify-between text-yellow-600 text-sm font-medium pt-1 border-t">
                          <span>Diferença:</span>
                          <span>-$10.00/mês</span>
                        </div>
                      </div>
                    </div>
                  </DialogDescription>
                </DialogHeader>
                
                <DialogFooter className="space-x-2">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setShowCancelDialog(false);
                      setSelectedApp(null);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => selectedApp && handleCancelSubscription(selectedApp.id)}
                    disabled={cancelingApp === selectedApp?.id}
                  >
                    {cancelingApp === selectedApp?.id ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                        Cancelando...
                      </>
                    ) : (
                      'Confirmar Cancelamento'
                    )}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>

      {showTour && applications.length > 0 && (
        <ApplicationTour onComplete={handleTourComplete} />
      )}
    </>
  );
};

export default ApplicationManager;