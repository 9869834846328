import { useEffect, useState } from 'react';
import { Progress } from '@/shadcn/components/ui/progress';
import { Card, CardContent } from '@/shadcn/components/ui/card';
import { Server } from 'lucide-react';
import { db } from '../firebase/config';
import { doc, onSnapshot, collection } from 'firebase/firestore';
import { useAuthContext } from "../hooks/useAuthContext";

const AppLimitProgress = () => {
  const [currentUserData, setCurrentUserData] = useState(null);
  const [appsInUse, setAppsInUse] = useState(0);
  const { user } = useAuthContext();
  const MAX_APPS = 100;

  useEffect(() => {
    if (!user?.uid) return;

    // Setup user document listener
    const userRef = doc(db, 'users', user.uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setCurrentUserData({ id: doc.id, ...doc.data() });
      }
    });

    // Setup applications collection listener
    const applicationsCollectionRef = collection(db, 'users', user.uid, 'applications');
    const unsubscribeApps = onSnapshot(applicationsCollectionRef, (snapshot) => {
      setAppsInUse(snapshot.size);
    });

    return () => {
      unsubscribe();
      unsubscribeApps();
    };
  }, [user?.uid]);

  const appLimit = currentUserData?.appLimit || 0;
  const progressPercentage = Math.min((appsInUse / MAX_APPS) * 100, 100);
  const remainingApps = Math.max(appLimit - appsInUse, 0);

  return (
    <Card className="w-full">
      <CardContent className="p-4">
        <div className="flex items-center mb-3">
          <div className="p-1.5 bg-blue-100 rounded-lg dark:bg-blue-900 mr-2">
            <Server className="w-4 h-4 text-blue-600 dark:text-blue-300" />
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-baseline">
              <span className="text-sm font-medium">Aplicações Disponíveis</span>
              <span className="text-sm font-semibold">{appLimit}</span>
            </div>
            <div className="text-xs text-gray-500">Total permitido: {MAX_APPS}</div>
          </div>
        </div>

        <div className="space-y-1">
          <Progress
            value={progressPercentage}
            className="h-1.5"
            indicatorClassName={appsInUse > 0 ? "bg-green-500" : "bg-gray-200"}
          />
          <div className="flex justify-between text-xs text-gray-500">
            <span>{appsInUse > 0 ? `${appsInUse} em uso` : "Nenhuma aplicação em uso"}</span>
            <span>{remainingApps} Aplicações restantes</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AppLimitProgress;