import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/shadcn/components/ui/card";
import { useTheme } from "../../Providers/ThemeProvider";
import { useAuthContext } from '@/hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';

const NotionCalendar = () => {
  const { theme } = useTheme();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const isDark = theme === 'dark';

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    // Check if Cal.com is already initialized
    if (window.Cal?.loaded) {
      return;
    }

    // Initialize Cal.com
    (function (C, A, L) { 
      let p = function (a, ar) { a.q.push(ar); }; 
      let d = C.document; 
      C.Cal = C.Cal || function () { 
        let cal = C.Cal; 
        let ar = arguments; 
        if (!cal.loaded) { 
          cal.ns = {}; 
          cal.q = cal.q || []; 
          d.head.appendChild(d.createElement("script")).src = A; 
          cal.loaded = true; 
        } 
        if (ar[0] === L) { 
          const api = function () { p(api, arguments); }; 
          const namespace = ar[1]; 
          api.q = api.q || []; 
          if(typeof namespace === "string") {
            cal.ns[namespace] = cal.ns[namespace] || api;
            p(cal.ns[namespace], ar);
            p(cal, ["initNamespace", namespace]);
          } else p(cal, ar); 
          return;
        } 
        p(cal, ar); 
      }; 
    })(window, "https://app.cal.com/embed/embed.js", "init");

    const initCalendar = () => {
      window.Cal("init", "call-gratuita", {origin:"https://cal.com"});
      window.Cal.ns["call-gratuita"]("inline", {
        elementOrSelector:"#my-cal-inline",
        config: {
          "layout":"month_view",
          "theme": isDark ? "dark" : "light"
        },
        calLink: "automacoes-geral-wbenhl/call-gratuita",
      });
      window.Cal.ns["call-gratuita"]("ui", {
        "hideEventTypeDetails":false,
        "layout":"month_view",
        "theme": isDark ? "dark" : "light",
        "hideBranding": true
      });
    };

    // Wait for element to be ready
    const checkElement = setInterval(() => {
      if (document.getElementById("my-cal-inline")) {
        clearInterval(checkElement);
        initCalendar();
      }
    }, 100);

    return () => {
      clearInterval(checkElement);
      // Remove script and clear instances
      const scripts = document.querySelectorAll('script[src="https://app.cal.com/embed/embed.js"]');
      scripts.forEach(script => script.remove());
      
      // Clear Cal namespace
      if (window.Cal?.ns) {
        Object.keys(window.Cal.ns).forEach(key => {
          delete window.Cal.ns[key];
        });
      }
      // Reset Cal.com loaded state
      if (window.Cal) {
        window.Cal.loaded = false;
      }
      
      // Clear any existing calendar elements
      const calElement = document.getElementById("my-cal-inline");
      if (calElement) {
        calElement.innerHTML = "";
      }
    };
  }, [user, navigate, isDark]);

  if (!user) return null;

  return (
    <div className={`min-h-screen p-8 ${isDark ? '!bg-slate-900' : 'bg-[hsl(var(--background))]'}`}>
      <div className="max-w-6xl mx-auto">
        <Card className={isDark ? 'bg-slate-800/50 border-slate-700 text-white' : ''}>
          <CardHeader>
            <CardTitle className="text-2xl font-bold">Agendar Call</CardTitle>
          </CardHeader>
          <CardContent>
            <div 
              id="my-cal-inline" 
              style={{ width: "100%", height: "100%", overflow: "scroll" }}
              className="h-[800px]"
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default NotionCalendar;