import React, { useState } from "react";
import {
  Calendar,
  Home,
  Inbox,
  Settings,
  ChevronUp,
  ChevronDown,
  BadgeCheck,
  CreditCard,
  LogOut,
  Bot,
  Database,
  Star,
  Handshake,
  BarChart2,
} from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/shadcn/components/ui/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/shadcn/components/ui/avatar";
import { 
  DropdownMenu, 
  DropdownMenuTrigger, 
  DropdownMenuContent, 
  DropdownMenuItem 
} from "@/shadcn/components/ui/dropdown-menu";
import getInitials from "@/utils/getInitials";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import BalanceProgress from './BalanceProgress';

const items = [
  {
    title: "IA chat",
    url: "/projects",
    icon: Bot,
    children: [
      { title: "Deployer", url: "/projects/" },
  
    ],
  },
  {
    title: "Contate nossa equipe",
    url: "/solicite",
    icon: Handshake,
  },
  {
    title: "Minhas aplicações",
    url: "/aplicacoes",
    icon: Database,
  },
  {
    title: "Tutoriais",
    url: "/tutoriais",
    icon: Settings,
  },
  {
    title: "Pagamento",
    url: "/pricing",
    icon: CreditCard,
  },
];

export function AppSidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <Sidebar className="h-screen w-[270px] bg-white dark:bg-dark-tremor-background-muted border-r-2 border-gray-300 dark:border-dark-tremor-border flex flex-col justify-between">
      <SidebarContent>
        <div className="flex items-center justify-center py-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logo_normal__1_-removebg.png?alt=media&token=26cbb328-5470-44e2-a6b2-5d48ccf565ba"
            alt="Logo"
          />
        </div>

        {/* Balance Progress Component */}
        

        {/* Navigation Menu */}
        <SidebarGroup className="mt-4">
          <SidebarGroupContent>
            <SidebarMenu>
              {/* Item com colapso */}
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <div
                    role="button"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className="p-4 text-gray-800 dark:text-dark-tremor-content-default hover:bg-gray-200 dark:hover:bg-dark-tremor-background-subtle rounded-lg text-[15px] font-semibold flex items-center transition duration-200 ease-in-out cursor-pointer"
                  >
                    <Bot className="mr-2" />
                    <span>IA chat</span>
                    {isCollapsed ? (
                      <ChevronUp className="ml-auto" />
                    ) : (
                      <ChevronDown className="ml-auto" />
                    )}
                  </div>
                </SidebarMenuButton>
                <div
                  className={`transition-all duration-300 overflow-hidden ${
                    isCollapsed ? "max-h-[100px]" : "max-h-0"
                  }`}
                >
                  {items[0].children.map((child, index) => (
                    <div
                      key={index}
                      role="button"
                      onClick={() => navigate(child.url)}
                      className={`pl-10 py-2 text-gray-600 dark:text-dark-tremor-content-muted hover:bg-gray-200 dark:hover:bg-dark-tremor-background-subtle rounded-lg text-[14px] font-medium cursor-pointer ${
                        location.pathname === child.url
                          ? "bg-gray-200 dark:bg-gray-700"
                          : ""
                      }`}
                    >
                      {child.title}
                    </div>
                  ))}
                </div>
              </SidebarMenuItem>

              {/* Outros itens */}
              {items.slice(1).map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <div
                      role="button"
                      onClick={() => navigate(item.url)}
                      className={`p-4 text-gray-800 dark:text-dark-tremor-content-default hover:bg-gray-100 dark:hover:bg-dark-tremor-background-subtle rounded-lg text-[15px] font-semibold flex items-center transition duration-200 ease-in-out ${
                        location.pathname === item.url
                          ? "bg-gray-200 dark:bg-gray-700 border-l-4 border-zinc-800 dark:border-dark-tremor-emphasis"
                          : ""
                      }`}
                    >
                      <item.icon className="mr-2" />
                      <span>{item.title}</span>
                    </div>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <BalanceProgress data-apps-available />
      {/* User Profile Dropdown Menu */}
      <div className="p-4">
        <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              className="flex items-center h-20 rounded-lg text-gray-800 dark:text-dark-tremor-content-strong cursor-pointer"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <Avatar className="mr-2">
                <AvatarImage src={user.photoURL} />
                <AvatarFallback className="bg-primary/50">
                  {getInitials(user.displayName)}
                </AvatarFallback>
              </Avatar>
              <span className="font-semibold">{user.displayName}</span>
              {isDropdownOpen ? (
                <ChevronUp className="ml-auto" />
              ) : (
                <ChevronDown className="ml-auto" />
              )}
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            side="right"
            className={`bg-black text-white ml-4 mt-2 mb-20 border border-gray-700 w-64 rounded-lg shadow-lg transition-all duration-300 ease-in-out overflow-hidden ${
              isDropdownOpen ? "max-h-[300px]" : "max-h-0"
            }`}
          >
            <div className="p-4 items-center border-b border-gray-700">
              <Avatar className="flex flex-col mb-2 w-12 h-12">
                <AvatarImage src={user.photoURL} />
                <AvatarFallback className="bg-primary/50">
                  {getInitials(user.displayName)}
                </AvatarFallback>
              </Avatar>
              <span className="flex flex-col font-semibold">{user.displayName}</span>
              <span className="text-sm text-gray-400">{user.email}</span>
            </div>
            
            <DropdownMenuItem
              onClick={() => navigate("/profile")}
              className="flex items-center px-4 py-2 hover:bg-gray-800 cursor-pointer"
            >
              <BadgeCheck className="mr-2" /> Perfil
            </DropdownMenuItem>
            
            <DropdownMenuItem
              onClick={handleLogout}
              className="flex items-center px-4 py-2 hover:bg-gray-800 cursor-pointer border-t border-gray-700 mt-2"
            >
              <LogOut className="mr-2" /> Sair da conta
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </Sidebar>
  );
}