import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/shadcn/components/ui/dialog";
import { Input } from "@/shadcn/components/ui/input";
import { Button } from "@/shadcn/components/ui/button";
import { Alert, AlertDescription } from "@/shadcn/components/ui/alert";
import { Loader2 } from "lucide-react";
import { Label } from "@/shadcn/components/ui/label";
import { cn } from "@/lib/utils";

const AppLimitModal = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  appCount, 
  setAppCount, 
  loading, 
  error,
  price = 10,
  isUpdate = false,
  currentAppLimit = 0
}) => {
  useEffect(() => {
    if (isOpen) {
      setAppCount(isUpdate ? currentAppLimit.toString() : '1');
    }
  }, [isOpen, currentAppLimit, isUpdate, setAppCount]);

  const parsedAppCount = parseInt(appCount) || 0;
  const isIncrease = parsedAppCount > currentAppLimit;
  const isDecrease = parsedAppCount < currentAppLimit;
  const priceDifference = Math.abs(parsedAppCount - currentAppLimit) * price;

  const getTotalUSD = () => {
    const count = parseInt(appCount) || 1;
    return (count * price).toFixed(2);
  };

  const renderPricingInfo = () => {
    if (!isUpdate) {
      return (
        <div className="space-y-1">
          <div className="flex justify-between font-medium">
            <span>Total:</span>
            <span>${getTotalUSD()}/mês</span>
          </div>
          <div className="text-xs text-muted-foreground">
            ${price},00 por aplicativo/mês
          </div>
        </div>
      );
    }

    const newTotal = getTotalUSD();
    const currentTotal = (currentAppLimit * price).toFixed(2);

    return (
      <div className="space-y-2 border rounded-lg p-3 bg-gray-50">
        <div className="flex justify-between text-sm">
          <span>Total atual:</span>
          <span>R${currentTotal}/mês</span>
        </div>
        <div className="flex justify-between font-medium">
          <span>Novo total:</span>
          <span>R${newTotal}/mês</span>
        </div>
        {(isIncrease || isDecrease) && (
          <div className={cn(
            "flex justify-between text-sm font-medium mt-1 pt-1 border-t",
            isIncrease ? "text-green-600" : "text-yellow-600"
          )}>
            <span>Diferença:</span>
            <span>{isIncrease ? '+' : '-'}${priceDifference.toFixed(2)}/mês</span>
          </div>
        )}
      </div>
    );
  };

  const getButtonStyle = () => {
    if (!isUpdate) return "bg-blue-500 hover:bg-blue-600";
    if (isIncrease) return "bg-green-500 hover:bg-green-600";
    if (isDecrease) return "bg-yellow-500 hover:bg-yellow-600";
    return "bg-blue-500 hover:bg-blue-600";
  };

  const getButtonText = () => {
    if (loading) {
      return (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Processando...
        </>
      );
    }

    if (!isUpdate) return 'Prosseguir para Pagamento';
    if (isIncrease) return `Adicionar ${parsedAppCount - currentAppLimit} Aplicativo${parsedAppCount - currentAppLimit !== 1 ? 's' : ''}`;
    if (isDecrease) return `Remover ${currentAppLimit - parsedAppCount} Aplicativo${currentAppLimit - parsedAppCount !== 1 ? 's' : ''}`;
    return 'Atualizar Assinatura';
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {isUpdate ? 'Atualizar Limite de Aplicações para hospedagem' : 'Adicionar Aplicações para hospedagem'}
          </DialogTitle>
          <DialogDescription>
            {isUpdate 
              ? 'Ajuste seu limite de Aplicações para hospedagem. As alterações terão efeito imediato.'
              : `Escolha quantas Aplicações para hospedagem você deseja. Cada porta custa $${price}/mês e te da direito a 1 instalação.`
            }
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="space-y-3">
            <Label htmlFor="appCount" className="text-sm font-medium">
              Número de Aplicações para hospedagem
            </Label>
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="icon"
                className="h-8 w-8"
                onClick={() => {
                  const current = parseInt(appCount) || 1;
                  if (current > 1) setAppCount((current - 1).toString());
                }}
                disabled={loading || !appCount || parseInt(appCount) <= 1}
              >
                -
              </Button>
              <Input
                id="appCount"
                type="number"
                value={appCount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^\d]/g, '');
                  if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 100)) {
                    setAppCount(value);
                  }
                }}
                min="1"
                max="100"
                className="text-center"
                disabled={loading}
              />
              <Button
                variant="outline"
                size="icon"
                className="h-8 w-8"
                onClick={() => {
                  const current = parseInt(appCount) || 1;
                  if (current < 100) setAppCount((current + 1).toString());
                }}
                disabled={loading || parseInt(appCount) >= 100}
              >
                +
              </Button>
            </div>
            
            <div className="mt-4">
              {renderPricingInfo()}
            </div>

            {isUpdate && (
              <div className="text-sm text-muted-foreground mt-2">
                Limite atual: {currentAppLimit} Aplicacoes disponiveis para hospedagem 
              </div>
            )}
          </div>

          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        </div>

        <DialogFooter className="gap-2 sm:gap-0">
          <Button variant="outline" onClick={onClose} disabled={loading}>
            Cancelar
          </Button>
          <Button 
            onClick={onSubmit} 
            disabled={loading || !appCount || parseInt(appCount) < 1 || (isUpdate && parseInt(appCount) === currentAppLimit)}
            className={cn(
              "relative",
              getButtonStyle()
            )}
          >
            {getButtonText()}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AppLimitModal;