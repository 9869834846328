import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@/shadcn/components/ui/button";
import { Checkbox } from "@/shadcn/components/ui/checkbox";
import { useToast } from "@/shadcn/hooks/use-toast";
import { X } from "lucide-react";

import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "@/shadcn/components/ui/card";
const TOUR_STEPS = [
  {
    target: "logo",
    title: "Bem-vindo ao iLoveDeploy! 👋",
    description: "Vamos conhecer nossa plataforma!",
    getPosition: () => ({
      top: "18px",
      left: "8px",
      width: "260px",
      height: "200px",
    }),
  },
  {
    target: "ia-chat",
    title: "Chat com IA 🤖",
    description:
      "Solicite o seu Open Source com a nossa IA, basta digitar: Quero o meu N8N, Quero o meu Mautic Quero o meu Typebot...",
    getPosition: () => ({
      top: "254px",
      left: "0",
      width: "200px",
      height: "40px",
    }),
  },
  {
    target: "projects",
    title: "Contrate nossa equipe 📝",
    description:
      "Precisa de ajuda com um grande projeto? Solicite um agora mesmo com nossa equipe de especialisatas.",
    getPosition: () => ({
      top: "290px",
      left: "0",
      width: "220px",
      height: "40px",
    }),
  },
  {
    target: "apps",
    title: "Suas Aplicações 📱",
    description: "Gerencie e acesse todas as suas aplicações instaladas aqui.",
    getPosition: () => ({
      top: "326px",
      left: "0",
      width: "200px",
      height: "40px",
    }),
  },
  {
    target: "tutorials",
    title: "Tutoriais 📚",
    description: "Em breve alguns tutoriais em video serão disponibilizados.",
    getPosition: () => ({
      top: "362px",
      left: "0",
      width: "200px",
      height: "40px",
    }),
  },
  {
    target: "payment",
    title: "Pagamento 💳",
    description:
      "Gerencie seus pagamentos e limites de aplicações para serem hospedadas.",
    getPosition: () => ({
      top: "398px",
      left: "0",
      width: "200px",
      height: "40px",
    }),
  },
  {
    target: "apps-available",
    title: "Aplicações disponíveis ⚡",
    description:
      "Esse pequeno gráfico mostra seu limite atual de aplicações e quantas aplicações estão em uso atualmente.",
    getPosition: () => {
      // Procura primeiro pelo elemento com data attribute
      const element = document.querySelector("[data-apps-available]");
      if (element) {
        const rect = element.getBoundingClientRect();
        return {
          top: `${rect.top}px`,
          left: "0",
          width: "269px",
          height: "105px",
        };
      }

      // Se não encontrar, usa uma posição fixa mais adequada
      const viewportHeight = window.innerHeight;
      return {
        top: `${viewportHeight - 220}px`, // 240px do final da viewport
        left: "0",
        width: "269px",
        height: "105px",
      };
    },
  },
];


const getCardPosition = () => {
  const sidebarWidth = 270;
  const topPosition = parseInt(highlightPosition.top);
  const windowHeight = window.innerHeight;
  const cardHeight = 250;
  const padding = 20;

  // Ajuste especial para o último passo (apps-available)
  if (currentStep === TOUR_STEPS.length - 1) {
    return {
      top: `${topPosition - cardHeight - padding}px`,
      left: `${sidebarWidth + padding}px`,
      width: "400px",
    };
  }

  // Para os outros passos, mantém a lógica original
  const wouldOverflow = topPosition + cardHeight + padding > windowHeight;
  return {
    top: wouldOverflow
      ? `${topPosition - cardHeight - padding}px`
      : `${topPosition}px`,
    left: `${sidebarWidth + padding}px`,
    width: "400px",
  };
};

const OnboardingTour = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [neverShowAgain, setNeverShowAgain] = useState(false);
  const [highlightPosition, setHighlightPosition] = useState({});
  const { toast } = useToast();

  useEffect(() => {
    const updatePosition = () => {
      const newPosition = TOUR_STEPS[currentStep].getPosition();
      setHighlightPosition(newPosition);
    };

    updatePosition();
    window.addEventListener("resize", updatePosition);

    return () => window.removeEventListener("resize", updatePosition);
  }, [currentStep]);

  const currentStepData = TOUR_STEPS[currentStep];
  const isLastStep = currentStep === TOUR_STEPS.length - 1;

  const getCardPosition = () => {
    const sidebarWidth = 270;
    const topPosition = parseInt(highlightPosition.top);
    const windowHeight = window.innerHeight;
    const cardHeight = 250; // altura aproximada do card
    const padding = 20;

    // Verifica se o card vai ultrapassar o limite inferior da tela
    const wouldOverflow = topPosition + cardHeight + padding > windowHeight;

    return {
      top: wouldOverflow
        ? `${topPosition - cardHeight - padding}px` // posiciona acima do elemento
        : `${topPosition}px`, // posiciona na altura do elemento
      left: `${sidebarWidth + padding}px`,
      width: "400px",
    };
  };

  const handleComplete = () => {
    if (onComplete) {
      onComplete(neverShowAgain);
    }
    toast({
      title: "Tour concluído! 🎉",
      description: neverShowAgain
        ? "Você optou por não ver mais o tour."
        : "Você pode reiniciar o tour a qualquer momento.",
      duration: 3000,
    });
  };

  return (
    <>
      <div className="fixed inset-0 bg-black/80 z-[60]" />

      <div
        className="fixed z-[70] bg-white/5 border border-white/10 rounded-lg transition-all duration-300"
        style={highlightPosition}
      />

      <Card 
        className="fixed z-[80] w-[400px] bg-zinc-900/95 border-zinc-800 transition-all duration-300"
        style={getCardPosition()}
      >
        <CardHeader className="p-3 border-b border-zinc-800">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-white flex items-center gap-2">
              {currentStepData.title}
            </h3>
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 rounded-md hover:bg-zinc-800"
              onClick={handleComplete}
            >
              <X className="h-4 w-4 text-zinc-400" />
            </Button>
          </div>
        </CardHeader>

        <CardContent className="p-3">
          <p className="text-sm text-zinc-400">
            {currentStepData.description}
          </p>
        </CardContent>

        <CardFooter className="flex flex-col gap-3 p-0">
          <div className="flex items-center justify-between w-full p-3 border-t border-zinc-800">
            <div className="flex gap-2">
              {currentStep > 0 && (
                <button
                  onClick={() => setCurrentStep(prev => prev - 1)}
                  className="px-3 py-1.5 text-sm text-white bg-transparent border border-zinc-800 rounded-md hover:bg-zinc-800"
                >
                  Anterior
                </button>
              )}
              <button
                onClick={() => {
                  if (currentStep < TOUR_STEPS.length - 1) {
                    setCurrentStep(prev => prev + 1);
                  } else {
                    handleComplete();
                  }
                }}
                className="px-3 py-1.5 text-sm text-black bg-white rounded-md hover:bg-white/90"
              >
                {isLastStep ? 'Concluir' : 'Próximo'}
              </button>
            </div>
            <span className="text-xs text-zinc-500">
              {currentStep + 1}/{TOUR_STEPS.length}
            </span>
          </div>

          {isLastStep && (
            <div className="px-3 pb-2 w-full">
              <label className="flex items-center gap-2 text-sm text-zinc-400">
                <Checkbox
                  checked={neverShowAgain}
                  onCheckedChange={setNeverShowAgain}
                  className="border-zinc-700 data-[state=checked]:bg-white data-[state=checked]:text-black"
                />
                Não mostrar mais o tour
              </label>
            </div>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

export default OnboardingTour;