import React, { useRef, useState } from "react";
import { Button } from "@/shadcn/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shadcn/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/shadcn/components/ui/accordion";
import {
  Layers,
  MailCheck,
  Flame,
  Webhook,
  AlignStartVertical,
  ShieldAlert,
  Fingerprint,
  Check,
  X,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
} from "lucide-react";
import { Switch } from "@/shadcn/components/ui/switch";

const features = [
  {
    icon: MailCheck,
    title: "Melhor taxa de entrega do mercado",
    description:
      "Ter ótimas taxas de entrega é uma obrigação quando o assunto é email marketing e nós te proporcionamos isso!",
  },
  {
    icon: Flame,
    title: "Envio ilimitado",
    description:
      "Proteja seus deployments com nossa camada de segurança avançada e monitoramento em tempo real.",
  },
  {
    icon: AlignStartVertical,
    title: "Dashboards precisos",
    description:
      "Tenha métricas completamente precisas e extraia o maximo de informações das suas campanhas!",
  },
  {
    icon: Webhook,
    title: "Integração simples e rápida",
    description:
      "Integre e gerencie seus webhooks com suas plataformas de venda rapidamente e sem complicações",
  },
  {
    icon: ShieldAlert,
    title: "Não sofra com bloqueios de publicidade",
    description:
      "Faça suas campanhas sem a dor de cabeça de ter alguma restrição ou perder sua conta",
  },
  {
    icon: Fingerprint,
    title: "Rotação de IP's dedicados",
    description:
      "Prezamos pela entrega dos seus emails por isso utilizamos nossa lógica exclusiva de envio com rotação rotação de ips dedicados",
  },
];

const faqs = [
  {
    question: "Como funciona a plataforma?",
    answer:
      "Nossa plataforma oferece uma interface intuitiva para gerenciar seus deploys com facilidade.",
  },
  {
    question: "Quais tecnologias são suportadas?",
    answer: "Suportamos uma ampla gama de tecnologias e frameworks modernos.",
  },
  {
    question: "Como começar?",
    answer: "Basta criar uma conta e seguir nosso guia de início rápido.",
  },
];

const FlowGenie = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const featuresRef = useRef(null);
  const toolsRef = useRef(null);
  const faqRef = useRef(null);

  const handleToggle = () => {
    setIsAnnual((prev) => !prev);
  };

  const pricingData = [
    {
      name: "Starter",
      monthlyPrice: "R$ 297",
      annualPrice: "R$ 267",
      description: "Para começar com força total",
      features: ["1.000 envios/mês", "Suporte por email", "Templates básicos"],
      isPopular: false,
      monthlyHref: "/pricing/free-monthly",
      annualHref: "/pricing/free-annual",
    },
    {
      name: "Pro",
      monthlyPrice: "R$ 597",
      annualPrice: "R$ 527",
      description: "Para negócios em crescimento",
      features: [
        "10.000 envios/mês",
        "Suporte prioritário",
        "Templates premium",
        "API access",
      ],
      isPopular: true,
      monthlyHref: "/pricing/startup-monthly",
      annualHref: "/pricing/startup-annual",
    },
    {
      name: "Enterprise",
      monthlyPrice: "R$ 997",
      annualPrice: "R$ 927",
      description: "Para grandes operações",
      features: [
        "Envios ilimitados",
        "Suporte 24/7",
        "IP Dedicado",
        "API personalizada",
      ],
      isPopular: false,
      monthlyHref: "/pricing/enterprise-monthly",
      annualHref: "/pricing/enterprise-annual",
    },
  ];

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const navItems = [
    { name: "Recursos", ref: featuresRef },
    { name: "Ferramentas", ref: toolsRef },
    { name: "Preços", ref: toolsRef },
    { name: "FAQ", ref: faqRef },
  ];

  const comparisonData = [
    {
      feature: "Envios ilimitados",
      flowGenie: true,
      activeCampaign: false,
      description: "Plano básico para baixo limite mensal de envio",
    },
    {
      feature: "Preço mensal",
      flowGenie: "R$ 297/mês",
      activeCampaign: "R$ 489/mês",
      description: "Para 5.000 contatos",
    },
    {
      feature: "IP's Dedicados",
      flowGenie: true,
      activeCampaign: false,
      description:
        "Utilizam IPs publicos que estão em blacklist para seus envios",
    },
    {
      feature: "Taxa de entrega",
      flowGenie: "Maior taxa de entrega do mercado",
      activeCampaign: "Envios inconsistentes e alta taxa de spam",
      description: "Média de entregas bem-sucedidas",
    },
    {
      feature: "Suporte personalizado",
      flowGenie: true,
      activeCampaign: false,
      description: "Atendimento direto e rápido",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Header */}
      <header className="container mx-auto px-4 py-6">
        <div className="flex flex-col items-center sm:flex-row sm:justify-between">
          <div className="mb-4 sm:mb-0 transition-all duration-300 ease-in-out  hover:shadow-[0_0_20px_rgba(168,85,247,0.4)] rounded-2xl cursor-pointer">
            <img
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FCaptura_de_tela_2024-12-18_150124-removebg-preview.png?alt=media&token=30283dcb-e784-44a9-8b83-984ed6be2d5b"
              className="h-10 w-auto sm:h-28 transition-transform duration-300"
            />
          </div>
          <nav className="flex justify-center flex-grow">
            <div className="flex gap-8 sm:gap-12">
              {navItems.map((item) => (
                <Button
                  key={item.name}
                  variant="ghost"
                  className="text-lg sm:text-xl text-white 
                    transition-all duration-300 ease-in-out
                    hover:text-purple-400 hover:scale-110
                    hover:shadow-[0_0_15px_rgba(168,85,247,0.4)]
                    font-semibold relative
                    after:absolute after:bottom-0 after:left-0 
                    after:w-full after:h-0.5 after:bg-purple-500
                    after:transform after:scale-x-0
                    after:transition-transform after:duration-300
                    hover:after:scale-x-100
                  "
                  onClick={() => scrollToSection(item.ref)}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="container mx-auto px-4 py-20 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-6">
          Faça disparos de e-mail
          <span className="text-purple-500"> Ilimitados</span>
          <br />
          pagando barato
        </h1>
        <p className="text-gray-400 max-w-2xl mx-auto mb-8">
          Tenha a melhor taxa de abertura do mercado e faça envios a vontade com
          a Flow Genie!
        </p>
        <Button
          className="
          bg-purple-600 hover:bg-purple-700 
          text-lg px-8 py-6
          transition-all duration-300 ease-in-out
          hover:scale-105
          hover:shadow-[0_0_20px_rgba(168,85,247,0.5)]
          relative group
          overflow-hidden
        "
        >
          <span className="relative z-10">Começar Agora</span>
          <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-purple-800 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
        </Button>
      </section>

      {/* Features Section */}
      <section ref={featuresRef} className="container mx-auto px-4 py-20">
        <h2 className="text-2xl font-bold mb-12 text-center">
          Recursos exclusivos da Flow Genie
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <Card
              key={index}
              className="bg-gray-800 border-gray-700 transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-[0_0_15px_rgba(168,85,247,0.4)] cursor-pointer group"
            >
              <CardContent className="p-6">
                <div className="flex flex-col items-center text-center">
                  <div className="w-12 h-12 bg-purple-600/20 rounded-xl flex items-center justify-center mb-4 transition-transform duration-300 group-hover:scale-110">
                    {feature.icon && (
                      <feature.icon
                        size={24}
                        className="text-purple-500 transition-colors duration-300 group-hover:text-purple-400"
                      />
                    )}
                  </div>
                  <h3 className="text-xl font-semibold mb-4 transition-colors duration-300 group-hover:text-purple-400">
                    {feature.title}
                  </h3>
                  <p className="text-gray-400">{feature.description}</p>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>
      {/* Comparação Section */}
      <section className="container mx-auto px-4 py-20 bg-gray-900">
        <h2 className="text-3xl font-bold text-center mb-12">
          Por que escolher a <span className="text-purple-500">Flow Genie</span>
          ?
        </h2>

        <div className="flex justify-center items-center gap-8 mb-12">
          <div className="text-center group">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FCaptura_de_tela_2024-12-18_150124-removebg-preview.png?alt=media&token=30283dcb-e784-44a9-8b83-984ed6be2d5b"
              alt="Flow Genie Logo"
              className="h-24 w-auto mb-4 transition-all duration-300 rounded-lg group-hover:scale-110 group-hover:shadow-[0_0_20px_rgba(168,85,247,0.5)]"
            />
          </div>
          <div className="text-4xl font-bold text-purple-500">VS</div>
          <div className="text-center group">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FActiveCampaign_logo.png?alt=media&token=70dfc011-a8d7-4f37-b634-ddc299be31b8"
              alt="Active Campaign Logo"
              className="h-14 w-auto mb-4 opacity-70 transition-all duration-300 rounded-lg group-hover:ring-2 group-hover:ring-red-500"
            />
          </div>
        </div>

        <Card className="bg-gray-800 border-gray-700 overflow-hidden">
          <CardContent className="p-0">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-0 divide-y md:divide-y-0 md:divide-x divide-gray-700">
              <div className="p-6 bg-gray-800">
                <h3 className="text-lg font-semibold text-gray-300">
                  Recursos
                </h3>
              </div>
              <div className="p-6 bg-purple-900/20">
                <h3 className="text-lg font-semibold text-purple-400">
                  Flow Genie
                </h3>
              </div>
              <div className="p-6 bg-gray-800/50">
                <h3 className="text-lg font-semibold text-gray-400">
                  Active Campaign
                </h3>
              </div>
              <div className="p-6 bg-gray-800">
                <h3 className="text-lg font-semibold text-gray-300">
                  Detalhes
                </h3>
              </div>
            </div>

            {comparisonData.map((item, index) => (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-4 gap-0 divide-y md:divide-y-0 md:divide-x divide-gray-700"
              >
                <div className="p-6 bg-gray-800">
                  <span className="font-medium">{item.feature}</span>
                </div>
                <div className="p-6 bg-purple-900/20">
                  {typeof item.flowGenie === "boolean" ? (
                    <Check
                      className={`h-6 w-6 ${
                        item.flowGenie ? "text-green-500" : "text-red-500"
                      }`}
                    />
                  ) : (
                    <span className="text-purple-400 font-semibold">
                      {item.flowGenie}
                    </span>
                  )}
                </div>
                <div className="p-6 bg-gray-800/50">
                  {typeof item.activeCampaign === "boolean" ? (
                    item.activeCampaign ? (
                      <Check className="h-6 w-6 text-green-500" />
                    ) : (
                      <X className="h-6 w-6 text-red-500" />
                    )
                  ) : (
                    <span className="text-gray-400">{item.activeCampaign}</span>
                  )}
                </div>
                <div className="p-6 bg-gray-800">
                  <span className="text-gray-400 text-sm">
                    {item.description}
                  </span>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>

        <div className="mt-12 text-center">
          <p className="text-purple-400 mb-2 max-w-2xl mx-auto text-3xl">
            Pense no quanto de dinheiro a mais você fará...
          </p>
          <p className="text-gray-400 mb-8 max-w-2xl text-xl mx-auto">
            Deixando de lado plataformas que limitam seus envios e optando por
            uma que além de reduzir seus custos…
          </p>

          <button
            className="
          bg-purple-600 hover:bg-purple-700 
          text-white font-bold 
          py-4 px-8 
          rounded-lg
          transition-all duration-300
          hover:scale-105
          hover:shadow-[0_0_20px_rgba(168,85,247,0.5)]
        "
          >
            Comece Agora Mesmo
          </button>
        </div>
      </section>

      {/* Pricing Section */}
      <section>
        <div className="flex flex-col space-y-8">
          <div className="container mx-auto py-10">
            {/* Title */}
            <div className="text-center mb-10">
              <h2 className="text-3xl font-bold text-white">Planos e Preços</h2>
              <p className="text-gray-400 mt-2">
                Escolha o plano ideal para o seu negócio
              </p>
            </div>

            {/* Switch */}
            <div className="flex justify-center items-center mb-8">
              <label className="text-gray-400 mr-3">Mensal</label>
              <Switch
                className="bg-purple-600"
                checked={isAnnual}
                onCheckedChange={handleToggle}
              />
              <label className="text-gray-400 ml-3">
                Anual{" "}
                <span className="text-xs font-semibold bg-purple-600 text-white px-2 py-1 rounded-full ml-2">
                  Economia de 20%
                </span>
              </label>
            </div>

            {/* Pricing Cards */}
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {pricingData.map((plan, index) => (
                <Card
                  key={index}
                  className={`
      bg-gray-800 
      border-gray-700 
      text-center 
      p-6 
      transition-all 
      duration-300 
      hover:shadow-lg
      ${
        plan.isPopular
          ? "border-purple-500 hover:shadow-purple-500/30"
          : "hover:shadow-white/10"
      }
    `}
                >
                  {plan.isPopular && (
                    <p className="text-xs bg-purple-600 text-white rounded-full px-3 py-1 inline-block mb-2">
                      Mais popular
                    </p>
                  )}
                  <h4 className="text-xl font-bold text-white mb-2">
                    {plan.name}
                  </h4>
                  <div className="relative h-20 mb-2">
                    {" "}
                    {/* Container fixo para os preços */}
                    <div
                      className={`
          absolute 
          w-full 
          transition-all 
          duration-300 
          transform
          ${isAnnual ? "translate-y-2 opacity-0" : "translate-y-0 opacity-100"}
        `}
                    >
                      <span className="text-4xl font-bold text-purple-500">
                        {plan.monthlyPrice}
                      </span>
                      <span className="text-sm text-gray-400">/mês</span>
                    </div>
                    <div
                      className={`
          absolute 
          w-full 
          transition-all 
          duration-300 
          transform
          ${isAnnual ? "translate-y-0 opacity-100" : "-translate-y-2 opacity-0"}
        `}
                    >
                      <span className="text-4xl font-bold text-purple-500">
                        {plan.annualPrice}
                      </span>
                      <span className="text-sm text-gray-400">/mês</span>
                    </div>
                  </div>
                  <p className="text-gray-400 mb-6">{plan.description}</p>
                  <ul className="space-y-3 mb-6">
                    {plan.features.map((feature, idx) => (
                      <li
                        key={idx}
                        className="text-gray-300 flex items-center justify-center"
                      >
                        <Check className="w-5 h-5 text-purple-500 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <Button
                    className="
          bg-purple-600 hover:bg-purple-700 
          text-lg px-36 py-4
          transition-all duration-300 ease-in-out
          hover:scale-105
          hover:shadow-[0_0_20px_rgba(168,85,247,0.5)]
          relative group
          overflow-hidden
        "
                  >
                    <span className="relative z-10">Começar Agora</span>
                    <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-purple-800 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  </Button>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section ref={faqRef} className="container mx-auto px-4 py-20">
        <h2 className="text-2xl font-bold mb-12 text-center">
          Perguntas frequentes
        </h2>
        <Accordion type="single" collapsible className="max-w-2xl mx-auto">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger
                className="
          text-left
          transition-all duration-300
          hover:text-purple-400
          group
        "
              >
                <span className="group-hover:scale-105 transition-transform duration-300 inline-block">
                  {faq.question}
                </span>
              </AccordionTrigger>
              <AccordionContent className="text-gray-400">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </section>

      {/* Footer */}
      <footer className="container mx-auto px-4 py-12 border-t border-gray-800">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <h3 className="text-xl font-bold text-white mb-4">Flow Genie</h3>
            <p className="text-gray-400">
              A melhor plataforma de email marketing do Brasil.
            </p>
          </div>

          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Produto</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Recursos
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Preços
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  API
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Integrações
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Empresa</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Sobre nós
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Blog
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Carreiras
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Contato
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Privacidade
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Termos
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  Cookies
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-purple-400">
                  LGPD
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
          <div className="text-gray-400 mb-4 md:mb-0">
            © 2024 Flow Genie. Todos os direitos reservados.
          </div>
          <div className="flex space-x-6">
            <a
              href="#"
              className="text-gray-400 hover:text-purple-400 transition-colors duration-200"
            >
              <span className="sr-only">Instagram</span>
              <Instagram size={24} />
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-purple-400 transition-colors duration-200"
            >
              <span className="sr-only">LinkedIn</span>
              <Linkedin size={24} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FlowGenie;
