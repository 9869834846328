import React, { useState, useEffect } from 'react';
import { Button } from "@/shadcn/components/ui/button";
import { Checkbox } from "@/shadcn/components/ui/checkbox";
import { useToast } from "@/shadcn/hooks/use-toast";
import { Card, CardHeader, CardContent, CardFooter } from "@/shadcn/components/ui/card";
import { X } from "lucide-react";

const TOUR_STEPS = [
  {
    target: "app-header",
    title: "Gerenciamento de Aplicações 📱",
    description: "Bem-vindo à área de gerenciamento! Aqui você pode visualizar e gerenciar todas as suas aplicações.",
    getPosition: () => {
      const element = document.querySelector('.app-header');
      if (!element) return null;
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`
      };
    }
  },
  {
    target: "app-limit",
    title: "Limite de Aplicações ⚡",
    description: "Aqui você pode ver seu limite atual de aplicações disponíveis para hospedagem.",
    getPosition: () => {
      const element = document.querySelector('.app-limit');
      if (!element) return null;
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`
      };
    }
  },
  {
    target: "app-card",
    title: "Detalhes da Aplicação 📋",
    description: "Cada card mostra informações importantes sobre sua aplicação, incluindo tipo, URL de acesso e status.",
    getPosition: () => {
      const element = document.querySelector('.app-card');
      if (!element) return null;
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`
      };
    }
  },
  {
    target: "app-status",
    title: "Status da Aplicação 🟢",
    description: "Monitore o status atual da sua aplicação - se está ativa ou precisa de atenção.",
    getPosition: () => {
      const element = document.querySelector('.app-status');
      if (!element) return null;
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`
      };
    }
  },
  {
    target: "app-actions",
    title: "Ações Disponíveis ⚙️",
    description: "Acesse sua aplicação diretamente ou cancele a porta se necessário. O cancelamento ajustará automaticamente sua cobrança mensal.",
    getPosition: () => {
      const element = document.querySelector('.app-actions');
      if (!element) return null;
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`
      };
    }
  }
];

const ApplicationTour = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [neverShowAgain, setNeverShowAgain] = useState(false);
  const [highlightPosition, setHighlightPosition] = useState({});
  const { toast } = useToast();

  useEffect(() => {
    const updatePosition = () => {
      const newPosition = TOUR_STEPS[currentStep].getPosition();
      if (newPosition) {
        setHighlightPosition(newPosition);
      }
    };

    updatePosition();

    // Atualiza posição em scroll e resize
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);

    // Atualiza posição periodicamente para garantir precisão
    const interval = setInterval(updatePosition, 100);

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
      clearInterval(interval);
    };
  }, [currentStep]);

  const getCardPosition = () => {
    const padding = 20;
    const cardWidth = 400;
    const cardHeight = 200;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const elementTop = parseInt(highlightPosition.top);
    const elementLeft = parseInt(highlightPosition.left);
    const elementWidth = parseInt(highlightPosition.width);
    const elementHeight = parseInt(highlightPosition.height);

    // Calcula espaço disponível em cada direção
    const spaceAbove = elementTop;
    const spaceBelow = windowHeight - (elementTop + elementHeight);
    const spaceLeft = elementLeft;
    const spaceRight = windowWidth - (elementLeft + elementWidth);

    let top = elementTop + elementHeight + padding;
    let left = elementLeft;

    // Se não houver espaço abaixo, posiciona acima
    if (spaceBelow < cardHeight && spaceAbove > cardHeight) {
      top = elementTop - cardHeight - padding;
    }

    // Se estiver muito à direita, alinha à direita do elemento
    if (left + cardWidth > windowWidth - padding) {
      left = windowWidth - cardWidth - padding;
    }

    // Se estiver muito à esquerda, alinha ao padding
    if (left < padding) {
      left = padding;
    }

    return {
      position: 'fixed',
      top: `${top}px`,
      left: `${left}px`,
      width: `${cardWidth}px`
    };
  };

  const handleComplete = () => {
    if (onComplete) {
      onComplete(neverShowAgain);
    }
    toast({
      title: "Tour concluído! 🎉",
      description: neverShowAgain
        ? "Você optou por não ver mais o tour."
        : "Você pode reiniciar o tour a qualquer momento.",
      duration: 3000,
    });
  };

  const currentStepData = TOUR_STEPS[currentStep];
  const isLastStep = currentStep === TOUR_STEPS.length - 1;

  return (
    <>
      <div className="fixed inset-0 bg-black/80 z-[60]" />

      <div
        className="fixed z-[70] bg-white/5 border border-white/10 rounded-lg transition-all duration-300"
        style={highlightPosition}
      />

      <Card 
        className="fixed z-[80] bg-zinc-900/95 border-zinc-800 transition-all duration-300"
        style={getCardPosition()}
      >
        <CardHeader className="p-3 border-b border-zinc-800">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-white flex items-center gap-2">
              {currentStepData.title}
            </h3>
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 rounded-md hover:bg-zinc-800"
              onClick={handleComplete}
            >
              <X className="h-4 w-4 text-zinc-400" />
            </Button>
          </div>
        </CardHeader>

        <CardContent className="p-3">
          <p className="text-sm text-zinc-400">
            {currentStepData.description}
          </p>
        </CardContent>

        <CardFooter className="flex flex-col gap-3 p-0">
          <div className="flex items-center justify-between w-full p-3 border-t border-zinc-800">
            <div className="flex gap-2">
              {currentStep > 0 && (
                <button
                  onClick={() => setCurrentStep(prev => prev - 1)}
                  className="px-3 py-1.5 text-sm text-white bg-transparent border border-zinc-800 rounded-md hover:bg-zinc-800"
                >
                  Anterior
                </button>
              )}
              <button
                onClick={() => {
                  if (currentStep < TOUR_STEPS.length - 1) {
                    setCurrentStep(prev => prev + 1);
                  } else {
                    handleComplete();
                  }
                }}
                className="px-3 py-1.5 text-sm text-black bg-white rounded-md hover:bg-white/90"
              >
                {isLastStep ? 'Concluir' : 'Próximo'}
              </button>
            </div>
            <span className="text-xs text-zinc-500">
              {currentStep + 1}/{TOUR_STEPS.length}
            </span>
          </div>

          {isLastStep && (
            <div className="px-3 pb-2 w-full">
              <label className="flex items-center gap-2 text-sm text-zinc-400">
                <Checkbox
                  checked={neverShowAgain}
                  onCheckedChange={setNeverShowAgain}
                  className="border-zinc-700 data-[state=checked]:bg-white data-[state=checked]:text-black"
                />
                Não mostrar mais o tour
              </label>
            </div>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

export default ApplicationTour;